import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";

import "./AddUpdate.css";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import moment from "moment";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "80vh",
  bgcolor: "#f9f9f9",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  maxHeight: "80vh",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const CargoSelect = ({ list, names, label }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCargoChange = (event) => {
    const selectedCargo = list?.find(
      (carg) => Number(carg?.id) === Number(event.target.value)
    )?.id;
    setFieldValue(names, selectedCargo);
  };

  return (
    <div className="form-group">
      <label>{label}</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name={names}
        value={values[`${names}`] || ""}
      >
        <option value={null}>Selecione</option>
        {list?.map((carg, id) => (
          <option key={id} value={carg?.id} style={{ cursor: "pointer" }}>
            {carg?.nome}
          </option>
        ))}
      </select>
      <ErrorMessage
        name={`${names}.name`}
        component="div"
        className="error-message"
      />
    </div>
  );
};

const SituacaoSelect = () => {
  const { values, setFieldValue } = useFormikContext();
  const handleCargoChange = (event) => {
    setFieldValue("situacao", Number(event.target.value)); // Converter o valor para número
  };

  return (
    <div className="form-group">
      <label>Situação</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name="situacao"
        value={Number(values?.situacao)} // Garantir que o valor é numérico
      >
        <option value={0}>Inativo</option>
        <option value={1}>Ativo</option>
      </select>
      <ErrorMessage name="situacao" component="div" className="error-message" />
    </div>
  );
};

const PixSelect = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleCargoChange = (event) => {
    setFieldValue("tipoPix", event.target.value);
  };

  return (
    <div className="form-group">
      <label>Tipo de Pix</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name="sutuacao"
        value={values.tipoPix}
      >
        <option value={""}>Selecione</option>
        <option value={"cpf"}>Cpf</option>
        <option value={"telefone"}>Telefone</option>
        <option value={"email"}>E-mail</option>
        <option value={"aleatoria"}>Aleatória</option>
      </select>
      <ErrorMessage name="tipoPix" component="div" className="error-message" />
    </div>
  );
};

const ProjetoSelect = ({ list }) => {
  const { values, setFieldValue } = useFormikContext();

  const selectedCargo = list?.find(
    (item) => Number(item?.id) === Number(values?.cidadeId)
  );

  const handleCargoChange = (event) => {
    setFieldValue("projeto", event.target.value);
  };

  return (
    <div className="form-group">
      <label>Projeto</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name="projeto"
        value={values?.projeto || ""}
        disabled={!selectedCargo}
      >
        <option value="">{!selectedCargo ? 'Escolha uma cidade...' : 'Selecione'}</option>
        {selectedCargo?.projetos?.map((proj, id) => (
          <option key={id} value={proj} style={{ cursor: "pointer" }}>
            {proj}
          </option>
        ))}
      </select>
      <ErrorMessage name="projeto" component="div" className="error-message" />
    </div>
  );
};

const TipoSelect = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleCargoChange = (event) => {
    setFieldValue("tipoPagamento", event.target.value);
  };

  return (
    <div className="form-group">
      <label>RPA OU MEI</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name="sutuacao"
        value={values.tipoPagamento}
      >
        <option value={""}>Selecione</option>
        <option value={"rpa"}>RPA</option>
        <option value={"mei"}>MEI</option>
      </select>
      <ErrorMessage
        name="tipoPagamento"
        component="div"
        className="error-message"
      />
    </div>
  );
};

const AddUpdate = ({ Button, value, cities }) => {
  const { setReloadColab, user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [listCargos, setListCargos] = useState([]);

  const [loading, setLoading] = useState(false);

  const getListItens = async () => {
    setLoading(true);
    try {
      const { data: cargos } = await api.get("/cargos");
      const { data: cidades } = await api.get("/cidades");

      if (cargos && cidades) {
        setListCargos(cargos);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListItens();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const cpfExists = async (cpf) => {
    try {
      if (value?.id) {
        return false;
      } else {
        const { data } = await api.get(`/funcionarios/cpf/${user?.id}`, {
          params: {
            cpf: cpf,
          },
        });
        return data; // Retorna `true` se o CPF já existe, `false` caso contrário
      }
    } catch (error) {
      console.error("Erro ao verificar CPF:", error);
      return false;
    }
  };

  const validationSchema = Yup.object({
    nome: Yup.string(),
    email: Yup.string().email("Email inválido"),
    telefone: Yup.string(),
    cpf: Yup.string().test(
      "checkDuplicateCPF",
      "Esse CPF já está cadastrado",
      async (value) => {
        if (!value) return true; // Se o CPF estiver vazio, deixa Yup lidar com a validação `required`

        // Remove todos os caracteres não numéricos (como . e -)
        const cleanedCpf = value.replace(/[^\d]/g, "");

        // Chama a função de verificação de CPF com o valor limpo
        if (cleanedCpf.length === 11) {
          const exists = await cpfExists(cleanedCpf);
          return !exists; // Retorna `false` se o CPF já existe, impedindo o cadastro
        } else {
          return true;
        }
      }
    ),
    situacao: Yup.number(),
    dataNascimento: Yup.date(),
    rg: Yup.string(),
    dataExpedicao: Yup.date(),
    uf: Yup.string(),
    orgaoExpedidor: Yup.string(),
    banco: Yup.string(),
    agencia: Yup.string(),
    conta: Yup.string(),
    codigoBanco: Yup.string(),
    chavePix: Yup.string(),
    tipoPix: Yup.string(),
    pisPasep: Yup.string(),
    escolaridade: Yup.string(),
    graduacao: Yup.string(),
    ajudaCusto: Yup.number(),
    salario: Yup.number(),
    cidadeId: Yup.number(),
    cargoId: Yup.number(),
    tipoPagamento: Yup.string(),
    projeto: Yup.string(),
  });

  const initialValues =
    user.typeUser === "admin" || user.typeUser === "Supervisor"
      ? {
          nome: value?.nome || "",
          email: value?.email || "",
          telefone: value?.telefone || "",
          cpf: value?.cpf || "",
          situacao: value?.situacao,
          dataNascimento: value?.dataNascimento
            ? moment(value?.dataNascimento).format("YYYY-MM-DD")
            : "",
          rg: value?.rg || "",
          dataExpedicao: value?.dataExpedicao
            ? moment(value?.dataExpedicao).format("YYYY-MM-DD")
            : "",
          uf: value?.uf || "",
          orgaoExpedidor: value?.orgaoExpedidor || "",
          banco: value?.banco || "",
          agencia: value?.agencia || "",
          conta: value?.conta || "",
          codigoBanco: value?.codigoBanco || "",
          chavePix: value?.chavePix || "",
          tipoPix: value?.tipoPix || "",
          pisPasep: value?.pisPasep || "",
          escolaridade: value?.escolaridade || "",
          graduacao: value?.graduacao || "",
          ajudaCusto: value?.ajudaCusto || "",
          salario: value?.salario || 0,
          cargoId: value?.cargo?.id || "",
          cidadeId: value?.cidade?.id || "",
          tipoPagamento: value?.tipoPagamento || "",
          projeto: value?.projeto || "",
        }
      : {
          nome: value?.nome || "",
          email: value?.email || "",
          telefone: value?.telefone || "",
          cpf: value?.cpf || "",
          situacao: value?.situacao,
          dataNascimento: value?.dataNascimento
            ? moment(value?.dataNascimento).format("YYYY-MM-DD")
            : "",
          rg: value?.rg || "",
          dataExpedicao: value?.dataExpedicao
            ? moment(value?.dataExpedicao).format("YYYY-MM-DD")
            : "",
          uf: value?.uf || "",
          orgaoExpedidor: value?.orgaoExpedidor || "",
          projeto: value?.projeto || "",
          banco: value?.banco || "",
          agencia: value?.agencia || "",
          conta: value?.conta || "",
          codigoBanco: value?.codigoBanco || "",
          chavePix: value?.chavePix || "",
          tipoPix: value?.tipoPix || "",
          pisPasep: value?.pisPasep || "",
          escolaridade: value?.escolaridade || "",
          graduacao: value?.graduacao || "",
          ajudaCusto: value?.ajudaCusto || "",
          salario: value?.salario || 0,
          cargoId: value?.cargo?.id || 1,
          cidadeId: value?.cidade?.id || user.typeUser,
          tipoPagamento: value?.tipoPagamento || "",
        };

  const saveFuncionario = async (values, resetForm) => {
    setLoading(true);
    const dadosFiltrados = Object.entries(values)
      .map(([key, value]) => [key, value === "" ? null : value]) // Substitui strings vazias por null
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    if (
      values.cidadeId === "" ||
      values.cargoId === "" ||
      values.tipoPagamento === ""
    ) {
      toast.error("Primeiro preencha o cargo , cidade de lotação e RPA OU MEI");
      setLoading(false);
    } else {
      try {
        if (value?.id) {
          // Se o ID existir, faz o update
          const { data } = await api.put(`/funcionarios/${value?.id}`, {
            ...dadosFiltrados,
          });
          toast.success("Funcionário atualizado com sucesso.");
          setOpen(false);
          return data; // Retorna os dados atualizados
        } else {
          // Se o ID não existir, faz a criação
          const { data } = await api.post("/funcionarios", {
            ...dadosFiltrados,
            situacao: 1,
          });

          toast.success("Funcionário criado com sucesso.");
          // setOpen(false);
          return data; // Retorna os dados do novo funcionário
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast.error("Não foi possível salvar o funcionário.");
      } finally {
        setLoading(false);
        setReloadColab(true);
        resetForm();
      }
    }
  };

  const handleSubmit = async (values, resetForm) => {
    await saveFuncionario(values, resetForm);
    // console.log(dadosFiltrados);
  };

  return (
    <div>
      <div onClick={handleOpen}>{Button}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm); // Chama a função de envio
            }}
          >
            {({ errors, touched }) => (
              <Form className="form-container">
                <h2>
                  {value ? "Editar Colaborador" : "Cadastro de Colaborador"}
                </h2>
                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Nome</label>
                    <Field name="nome" type="text" />
                    <ErrorMessage
                      name="nome"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <Field name="email" type="email" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Telefone</label>
                    <Field name="telefone" type="text" />
                    <ErrorMessage
                      name="telefone"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>CPF</label>
                    <Field name="cpf" type="text" />
                    <ErrorMessage
                      name="cpf"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Data de Nascimento</label>
                    <Field name="dataNascimento" type="date" />
                    <ErrorMessage
                      name="dataNascimento"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="containerJuntos">
                  <div className="form-group">
                    <label>RG</label>
                    <Field name="rg" type="text" />
                    <ErrorMessage
                      name="rg"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Data de Expedição</label>
                    <Field name="dataExpedicao" type="date" />
                    <ErrorMessage
                      name="dataExpedicao"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>UF</label>
                    <Field name="uf" type="text" />
                    <ErrorMessage
                      name="uf"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Órgão Expedidor</label>
                    <Field name="orgaoExpedidor" type="text" />
                    <ErrorMessage
                      name="orgaoExpedidor"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Código do Banco</label>
                    <Field name="codigoBanco" type="text" />
                    <ErrorMessage
                      name="codigoBanco"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Banco</label>
                    <Field name="banco" type="text" />
                    <ErrorMessage
                      name="banco"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Agência</label>
                    <Field name="agencia" type="text" />
                    <ErrorMessage
                      name="agencia"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Conta</label>
                    <Field name="conta" type="text" />
                    <ErrorMessage
                      name="conta"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Chave Pix</label>
                    <Field name="chavePix" type="text" />
                    <ErrorMessage
                      name="chavePix"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <Field component={PixSelect} />

                  <Field component={TipoSelect} />

                  <Field component={ProjetoSelect} list={cities} />
                </div>

                <div className="containerJuntos">
                  <div className="form-group">
                    <label>PIS/PASEP</label>
                    <Field name="pisPasep" type="text" />
                    <ErrorMessage
                      name="pisPasep"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Escolaridade</label>
                    <Field name="escolaridade" type="text" />
                    <ErrorMessage
                      name="escolaridade"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Graduação</label>
                    <Field name="graduacao" type="text" />
                    <ErrorMessage
                      name="graduacao"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                {(user.typeUser === "admin" ||
                  user.typeUser === "Supervisor") && (
                  <>
                    <div className="containerJuntos">
                      <div className="form-group">
                        <label>Salario</label>
                        <Field name="salario" type="number" />
                        <ErrorMessage
                          name="salario"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <div className="form-group">
                        <label>Ajuda de Custo</label>
                        <Field name="ajudaCusto" type="number" />
                        <ErrorMessage
                          name="ajudaCusto"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    <div className="containerJuntos">
                      {value && <Field component={SituacaoSelect} />}

                      <Field
                        component={CargoSelect}
                        list={listCargos}
                        names="cargoId"
                        label="Função"
                      />

                      <Field
                        component={CargoSelect}
                        list={cities}
                        names={"cidadeId"}
                        label={"Cidade"}
                      />

                      {/* <div className="form-group">
                    <label>Estado de atuação</label>
                    <Field name="cidadeId.estado" type="text" disabled />
                    <ErrorMessage
                      name="cidadeId.estado"
                      component="div"
                      className="error-message"
                    />
                  </div> */}
                    </div>
                  </>
                )}

                <button type="submit" className="submit-btn">
                  {loading ? "Salvando" : "Salvar"}
                </button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdate;
