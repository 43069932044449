import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { AuthContext } from "../../providers/AuthProvider";
import AddUpdate from "./components/AddUpdate";
import api from "../../utils/api";
import "./Cidades.css";
import Modals from "../../components/Modal";
import { Box, CircularProgress } from "@mui/material";

const Cidades = () => {
  const { reload, setReload } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/cidades");

      if (data) {
        setClients(data);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
      setReload(false);
    }
  };

  useEffect(() => {
    getClients();
  }, [reload]);

  return (
    <div className="containerFuncionarios">
      <div className="containerHeaderCidades">
        <div className="title">Cidades</div>

        <AddUpdate Button={<div className="button">Adicionar</div>} />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#147e45" }}>
              <TableCell sx={{ color: "#fff", width: '50%' }}>CIDADE</TableCell>
              <TableCell sx={{ color: "#fff", width: '50%' }}>ESTADO</TableCell>
              <TableCell align="right" sx={{ width: '50%' }}></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              padding: "40px",
              marginTop: "60px",
            }}
          >
            <CircularProgress sx={{ color: "#187648" }} />
          </Box>
        ) : (
          <TableBody>
            {clients?.map((row) => (
              <TableRow key={row.name} sx={{ width: '100%' }}>
                <TableCell sx={{ width: '50%' }}>{row.nome}</TableCell>
                <TableCell sx={{ width: '50%'}}>{row.estado}</TableCell>
                <TableCell
                  align="right"
                  sx={{  width: '50%', display: "flex", gap: "15px" }}
                >
                  <Modals
                    buttons={<DeleteIcon sx={{ color: "#d22c38" }} />}
                    title="APAGAR CIDADE"
                    id={row.id}
                    rota="cidades"
                    label={
                      <div>
                        Tem certeza de que deseja apagar a cidade{" "}
                        <strong>{row?.nome}</strong>? ao confirmar, essa ação
                        não podera ser desfeita.
                      </div>
                    }
                  />
                  <AddUpdate
                    Button={
                      <div className="button">
                        <EditIcon />
                      </div>
                    }
                    value={row}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </TableContainer>
    </div>
  );
};

export default Cidades;
