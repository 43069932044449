import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import "./AddUpdate.css";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { toast } from "react-toastify";

function isMobileDevice() {
  return window.innerWidth <= 768;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobileDevice() ? "80vw" : "600px",
  height: "500px",
  bgcolor: "#f9f9f9",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  maxHeight: "90vh",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const estadosBrasil = [
  "Acre (AC)",
  "Alagoas (AL)",
  "Amapá (AP)",
  "Amazonas (AM)",
  "Bahia (BA)",
  "Ceará (CE)",
  "Distrito Federal (DF)",
  "Espírito Santo (ES)",
  "Goiás (GO)",
  "Maranhão (MA)",
  "Mato Grosso (MT)",
  "Mato Grosso do Sul (MS)",
  "Minas Gerais (MG)",
  "Pará (PA)",
  "Paraíba (PB)",
  "Paraná (PR)",
  "Pernambuco (PE)",
  "Piauí (PI)",
  "Rio de Janeiro (RJ)",
  "Rio Grande do Norte (RN)",
  "Rio Grande do Sul (RS)",
  "Rondônia (RO)",
  "Roraima (RR)",
  "Santa Catarina (SC)",
  "São Paulo (SP)",
  "Sergipe (SE)",
  "Tocantins (TO)",
];

const EstadosSelect = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleCargoChange = (event) => {
    setFieldValue("estado", event.target.value);
  };

  return (
    <div className="form-group" style={{ width: "45%" }}>
      <label>Estado</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name={"estado"}
        value={values.estado || ""}
      >
        <option value="">Selecione</option>
        {estadosBrasil?.map((carg, id) => (
          <option key={id} value={carg} style={{ cursor: "pointer" }}>
            {carg}
          </option>
        ))}
      </select>
      <ErrorMessage name={"estado"} component="div" className="error-message" />
    </div>
  );
};

const ProjectsSelect = ({listProjects}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue(
      // On autofill we get a stringified value.
      "projetos",
      typeof value === "string" ? value.split(",") : value
    );
  };


  return (
    <div className="form-group" style={{ width: "45%" }}>
      <label>Projetos</label>
      <Select
        multiple
        value={values?.projetos}
        className="formSx" // Certifique-se de que as regras de estilo para .form-control estão definidas no CSS
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        id="demo-multiple-checkbox"
        sx={{
          border: "none", // Remove a borda
          "&:hover": {
            border: "none", // Remove a borda ao passar o mouse
          },
          "&.Mui-focused": {
            border: "none", // Remove a borda quando em foco
          },
        }}
      >
        {listProjects.map((proj) => (
          <MenuItem key={proj.name} value={proj.name}>
            <Checkbox checked={values.projetos.includes(proj.name)} />
            <ListItemText primary={proj.name} />
          </MenuItem>
        ))}
      </Select>

      <ErrorMessage
        name={"projetos"}
        component="div"
        className="error-message"
      />
    </div>
  );
};

const ImpostoSelect = ({ fieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCargoChange = (event) => {
    setFieldValue(fieldName, event.target.value);
  };

  return (
    <div className="form-group">
      <label>{`Descontar ${fieldName}`}</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name={fieldName}
        value={values[`${fieldName}`] || ""}
      >
        <option value="">Selecione</option>
        <option value={"sim"} style={{ cursor: "pointer" }}>
          Sim
        </option>
        <option value={"nao"} style={{ cursor: "pointer" }}>
          Não
        </option>
      </select>
      <ErrorMessage
        name={fieldName}
        component="div"
        className="error-message"
      />
    </div>
  );
};

const AddUpdate = ({ Button, value }) => {
  const { setReload } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listProjects, setListProjects] = useState([]);

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/projetos");

      if (data) {
        setListProjects(data);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    nome: Yup.string()
      .required("O nome da cidade é obrigatório")
      .min(2, "O nome da cidade deve ter pelo menos 2 caracteres")
      .max(100, "O nome da cidade deve ter no máximo 100 caracteres"),

    estado: Yup.string().required("O estado é obrigatório"),
    iss: Yup.string().required("O iss é obrigatório"),
    inss: Yup.string().required("O inss é obrigatório"),
    irrf: Yup.string().required("O irrf é obrigatório"),
    patronal: Yup.string().required("O irrf é obrigatório"),
  });

  const filteredItemNames = listProjects
  .filter((item) => value?.projetos.includes(item.name))
  .map((item) => item.name);

  const initialValues = {
    nome: value?.nome || "",
    estado: value?.estado || "",
    iss: value?.iss || "",
    inss: value?.inss || "",
    irrf: value?.irrf || "",
    projetos: value?.projetos ? filteredItemNames :  [],
    gt: value?.gt || "",
    patronal: value?.patronal || "",
  };


  const saveCidade = async (values) => {
    setLoading(true);
    const dadosFiltrados = Object.entries(values)
      .map(([key, value]) => [key, value === "" ? null : value]) // Substitui strings vazias por null
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    try {
      if (value?.id) {
        // Se o ID existir, faz o update
        const { data } = await api.put(`/cidades/${value?.id}`, {
          ...dadosFiltrados,
        });
        toast.success("cidade atualizada com sucesso.");
        handleClose();
        return data; // Retorna os dados atualizados
      } else {
        // Se o ID não existir, faz a criação
        const { data } = await api.post("/cidades", { ...dadosFiltrados });

        toast.success("cidade criada com sucesso.");
        return data; // Retorna os dados do novo funcionário
      }
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível salvar a cidade.");
    } finally {
      setLoading(false);
      setReload(true);
    }
  };

  const handleSubmit = async (values) => {
    await saveCidade(values);
    // console.log(dadosFiltrados);
  };

  // const selectedCargo = listCidades?.find((carg) => Number(carg.id) === Number(values.id)).estado;

  return (
    <div>
      <div onClick={handleOpen}>{Button}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values); // Chama a função de envio
              resetForm(); // Reseta o formulário para os valores iniciais
            }}
          >
            {() => (
              <Form className="form-container">
                <h2>{value ? "Editar cidade" : "Cadastrar cidade"}</h2>
                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Cidade</label>
                    <Field name="nome" type="text" />
                    <ErrorMessage
                      name="nome"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="form-group">
                    <label>Gestão técnica</label>
                    <Field name="gt" type="number" />
                    <ErrorMessage
                      name="gt"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="containerJuntos">
                  <Field component={EstadosSelect} />
                  <Field component={ProjectsSelect} listProjects={listProjects}/>
                </div>

                <div className="containerJuntos">
                  <Field component={ImpostoSelect} fieldName={"irrf"} />

                  <Field component={ImpostoSelect} fieldName={"patronal"} />
                </div>

                <div className="containerJuntos">
                  <Field component={ImpostoSelect} fieldName={"iss"} />

                  <Field component={ImpostoSelect} fieldName={"inss"} />
                </div>

                <button type="submit" className="submit-btn">
                  {loading ? "Salvando" : "Salvar"}
                </button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdate;
