import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import { AuthContext } from "../../providers/AuthProvider";
import AddUpdate from "./components/AddUpdate";
import api from "../../utils/api";
import "./projetos.css";
import Modals from "../../components/Modal";
import { Box, CircularProgress } from "@mui/material";

const Cargos = () => {
  const { reload, setReload } = useContext(AuthContext);
  const [listProjects, setListProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/projetos");

      if (data) {
        setListProjects(data);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
      setReload(false);
    }
  };

  useEffect(() => {
    getClients();
  }, [reload]);

  return (
    <div className="containerFuncionarios">
      <div className="containerHeaderCargos">
        <div className="title">Projetos</div>

        <AddUpdate Button={<div className="button">Adicionar</div>} />
      </div>
      <TableContainer component={Paper}>
      <Table aria-label="a dense table">
        <TableHead>
          <TableRow sx={{backgroundColor: '#147e45'}}>
            <TableCell sx={{color: '#fff'}}>PROJETO</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
      </Table>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            padding: "40px",
            marginTop: "60px",
          }}
        >
          <CircularProgress sx={{ color: "#187648" }} />
        </Box>
      ) : (
        // <div className="containerList">
        //   {listProjects
        //     ?.filter((i) => i.name !== "INDEFINIDO")
        //     .map((item, idx) => {
        //       return (
        //         <div
        //           key={idx}
        //           className={`card ${idx % 2 !== 0 && "colorBack"}`}
        //           style={{ padding: "10px" }}
        //         >
        //           <div className="item">{item?.name}</div>
        //           <Modals
        //         buttons={<DeleteIcon sx={{ color: "#d22c38" }} />}
        //         title="APAGAR PROJETO"
        //         id={item.id}
        //         rota="projetos"
        //         label={
        //           <div>
        //             Tem certeza de que deseja apagar o projeto{" "}
        //             <strong>{item?.name}</strong>? ao confirmar, essa
        //             ação não podera ser desfeita.
        //           </div>
        //         }
        //       />
        //           <AddUpdate
        //             Button={
        //               <div className="button">
        //                 <EditIcon />
        //               </div>
        //             }
        //             value={item}
        //           />
        //         </div>
        //       );
        //     })}
        // </div>
        <TableBody>
          {listProjects
            ?.filter((i) => i.name !== "INDEFINIDO")
            .map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell sx={{ width: '100%'}}>
                {row.name}
              </TableCell>
              <TableCell align="right" sx={{ width: '100%'}}>
              <AddUpdate
                    Button={
                      <div className="button">
                        <EditIcon />
                      </div>
                    }
                    value={row}
                  />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
       </TableContainer>
    </div>
  );
};

export default Cargos;
