import moment from "moment";
import React from "react";

const mesesCompletos = {
  janeiro: 1,
  fevereiro: 2,
  março: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

const SummaryTable = ({ filteredData, filters }) => {
  const agrupadosPorProjeto = filteredData.reduce((acc, item) => {
    const projeto = item.projeto;
    if (!acc[projeto]) {
      acc[projeto] = [];
    }
    acc[projeto].push(item);
    return acc;
  }, {});

  function patronal(salario, dados) {
    const valorServico = Number(salario);
    const patronal =
      dados.cidade.patronal === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.2
        : 0;
    return patronal;
  }

  function formatToBRL(value) {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  function calcularValorLiquido(salario, dados) {
    const valorServico = Number(salario);
    const inss =
      dados?.cidade?.inss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.11
        : 0;
    const baseCalculo =
      inss > 564.8 ? valorServico - inss : valorServico - 564.8;

    let irrf = 0;
    if (
      baseCalculo > 4664.68 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.275 - 896.0; // 27,5%
    } else if (
      baseCalculo > 3751.05 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.225 - 662.77; // 22,5%
    } else if (
      baseCalculo > 2826.65 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.15 - 381.44; // 15%
    } else if (
      baseCalculo > 2259.2 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.075 - 169.44; // 7,5%
    }

    const patronal =
      dados.cidade.patronal === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.2
        : 0; // Outros descontos
    return inss + irrf + patronal;
  }

  const resultado = Object.entries(agrupadosPorProjeto).map(
    ([projeto, pessoas]) => ({
      projeto,
      pessoas,
      totalSalario: pessoas.reduce((total, pessoa) => {
        // Encontre o salário correspondente ao mês e ano selecionados no filtro
        const salarioSelecionado = pessoa.historicoSalarios.find(
          (salario) =>
            salario.mes === mesesCompletos[filters.month] &&
            salario.year === parseInt(filters.year, 10)
        );

        // Se encontrar o salário, adicione ao total; caso contrário, adicione 0
        return total + (salarioSelecionado ? salarioSelecionado.valor : 0);
      }, 0),
      inss: pessoas.reduce((total, pessoa) => {
        const salarioSelecionado = pessoa.historicoSalarios.find(
          (salario) =>
            salario.mes === mesesCompletos[filters.month] &&
            salario.year === parseInt(filters.year, 10)
        );

        return (
          total +
          calcularValorLiquido(
            salarioSelecionado ? salarioSelecionado.valor : 0,
            pessoa
          )
        );
      }, 0),
      patronal: pessoas.reduce((total, pessoa) => {
        const salarioSelecionado = pessoa.historicoSalarios.find(
          (salario) =>
            salario.mes === moment(filters.month, "MMMM").month() + 1 &&
            salario.year === parseInt(filters.year, 10)
        );

        return (
          total +
          patronal(salarioSelecionado ? salarioSelecionado.valor : 0, pessoa)
        );
      }, 0),
    })
  );

  return (
    <div className="summary-table">
      <div className="summary-header">
        <div className="header-item">ORD</div>
        <div className="header-item">PROJETOS</div>
        <div className="header-item">SALÁRIO BRUTO</div>
        <div className="header-item">INSS (RF)</div>
        <div className="header-item">GESTÃO TÉCNICA</div>
        <div className="header-item">CUSTO FINAL</div>
      </div>

      {!filters.city && resultado.length <= 0 && (
        <div className="row-item" style={{ marginTop: "20px" }}>
          Nenhuma informação
        </div>
      )}
      {filters.city && resultado.length <= 0 && (
        <div className="row-item" style={{ marginTop: "20px" }}>
          Nenhuma informação
        </div>
      )}

      {resultado.map((item, idx) => (
        <div className="summary-row" key={idx}>
          <div className="row-item">{idx + 1}</div>
          <div className="row-item">{item.projeto}</div>
          <div className="row-item">{formatToBRL(item.totalSalario)}</div>
          <div className="row-item">{formatToBRL(item.inss)}</div>
          <div className="row-item">
            {formatToBRL(
              Number(item.totalSalario) * (Number(filters.city.gt) / 100)
            )}
          </div>
          <div className="row-item">
            {formatToBRL(
              Number(item.totalSalario) +
                Number(item.patronal) +
                Number(item.totalSalario) * (Number(filters.city.gt) / 100)
            )}
          </div>
        </div>
      ))}

      {filters.city && resultado.length > 0 && (
        <div className="summary-row totals">
          <div className="row-item">Total</div>
          <div className="row-item"></div>
          <div className="row-item">
            {formatToBRL(
              resultado.reduce(
                (acc, item) => acc + Number(item.totalSalario),
                0
              )
            )}
          </div>
          <div className="row-item">
            {formatToBRL(
              resultado.reduce((acc, item) => acc + Number(item.inss), 0)
            )}
          </div>
          <div className="row-item">
            {formatToBRL(
              resultado.reduce(
                (acc, item) =>
                  acc +
                  Number(item.totalSalario) * (Number(filters.city.gt) / 100),
                0
              )
            )}
          </div>
          <div className="row-item">
            {formatToBRL(
              resultado.reduce(
                (acc, item) =>
                  acc +
                  Number(item.totalSalario) +
                  Number(item.patronal) +
                  Number(item.totalSalario) * (Number(filters.city.gt) / 100),
                0
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryTable;
