import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EnhancedTable from "./components/EnhancedTable";
import AddUpdate from "./components/AddUpdate";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import "./Home.css";

const Home = () => {
  const { user, setReloadColab, reloadColab } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [citys, setCitys] = useState([]);

  // New filter states
  const [filters, setFilters] = useState({
    city: "",
    banco: "Todos",
    project: "Todos",
    situation: 1,
  });

  const getCitys = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/cidades");
      if (data) {
        setCitys(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getColabsFilter = async () => {
    setLoading(true);
    setClients([]);
    try {
      const { data } = await api.get(`/funcionarios/filters/${user?.id}`, {
        params: {
          situacao: filters.situation,
          cidadeId: filters.city.id,
          banco: filters.banco,
          projeto: filters.project,
        },
      });

      if (data) {
        setClients(data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível carregar a lista de funcionários.");
    } finally {
      setLoading(false);
      setReloadColab(false);
    }
  };

  const getColab = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/funcionarios/city/${user.typeUser}`);
      const { data: dataCities } = await api.get("/cidades");

      if (data && dataCities) {
        setClients(data);
        setCitys(dataCities);
      }
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível carregar a lista de colaboradores.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.typeUser !== "admin" || user?.typeUser !== 'Supervisor') {
      getColab();
    } else {
      getCitys();
    }
  }, []);

  const getColabsReload = async () => {
    try {
      const { data } = await api.get(`/funcionarios/filters/${user?.id}`, {
        params: {
          situacao: filters.situation,
          cidadeId: filters.city.id,
          banco: filters.banco,
          projeto: filters.project,
        },
      });

      if (data) {
        setClients(data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível carregar a lista de funcionários.");
    }
  };

  useEffect(() => {
    if (filters.city !== "" && reloadColab) {
      getColabsFilter();
    } else if (filters.city !== "") {
      getColabsReload();
    }
  }, [reloadColab]);

  const handleFilterChange = (field) => (event) => {
    setFilters((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const saveFuncionario = async (value, values) => {
    setLoading(true);
    const dadosFiltrados = Object.entries(values)
      .map(([key, value]) => [key, value === "" ? null : value])
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    if (values.cidadeId === "" || values.cargoId === "") {
      toast.error("Primeiro preencha o cargo e a cidade de lotação");
      setLoading(false);
      return;
    }

    try {
      if (value?.id) {
        const { data } = await api.put(
          `/funcionarios/${value?.id}`,
          dadosFiltrados
        );
        toast.success("Funcionário atualizado com sucesso.");
        return data;
      } else {
        const { data } = await api.post("/funcionarios", {
          ...dadosFiltrados,
          situacao: 1,
        });
        toast.success("Funcionário criado com sucesso.");
        return data;
      }
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível salvar o funcionário.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="containerFuncionarios">
      <div className="containerHeaderHome">
        <div></div>
        <AddUpdate
          Button={
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                height: 40,
                backgroundColor: "#167640",
                borderRadius: "8px",
              }}
            >
              ADICIONAR
            </Button>
          }
          saveFuncionario={saveFuncionario}
          cities={citys}
        />
      </div>

      {(user.typeUser === "admin" || user.typeUser === "Supervisor") && (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            mb: 3,
            flexWrap: "wrap",
          }}
        >
          <FormControl className="inputSEacrh" size="small" disabled={loading}>
            <InputLabel id="city-label">Cidade</InputLabel>
            <Select
              labelId="city-label"
              label="Cidade"
              value={filters.city}
              onChange={handleFilterChange("city")}
            >
              <MenuItem value="Todas">Todas</MenuItem>
              {citys?.map((city) => (
                <MenuItem key={city.id} value={city}>
                  {city.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="inputSEacrh" size="small" disabled={loading}>
            <InputLabel id="situation-label">Situação</InputLabel>
            <Select
              labelId="situation-label"
              label="Situação"
              value={filters.situation}
              onChange={handleFilterChange("situation")}
            >
              <MenuItem value={1}>Ativo</MenuItem>
              <MenuItem value={0}>Inativo</MenuItem>
              <MenuItem value="Todas">Todas</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="inputSEacrh" size="small" disabled={loading}>
            <InputLabel id="Banco-label">Banco</InputLabel>
            <Select
              labelId="Banco-label"
              label="Banco"
              value={filters.banco}
              onChange={handleFilterChange("banco")}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Banco do Brasil">Banco do Brasil</MenuItem>
              <MenuItem value="Outros Bancos">Outros Bancos</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="inputSEacrh" size="small" disabled={loading}>
            <InputLabel id="project-label">Projeto</InputLabel>
            <Select
              labelId="project-label"
              label="Projeto"
              value={filters.project}
              onChange={handleFilterChange("project")}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {filters?.city?.projetos?.map((project, idx) => (
                <MenuItem key={idx} value={project}>
                  {project}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <button onClick={getColabsFilter} className="buttoSEarch">
            <SearchIcon sx={{ width: "20px" }} />
            Buscar
          </button>
        </Box>
      )}

      <EnhancedTable
        clients={clients}
        cities={citys}
        user={user}
        setClients={setClients}
        loading={loading}
        cityId={filters.city.id}
      />
    </div>
  );
};

export default Home;
