import { useContext } from "react";
import {
  // BrowserRouter,
  Navigate,
  Route,
  Routes as RRDRoutes,
} from "react-router-dom";

import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "../components/AppNavbar";
import Header from "../components/Header";
import MainGrid from "../components/MainGrid";
import SideMenu from "../components/SideMenu";
import AppTheme from "../theme/shared-theme/AppTheme";

import Login from "../pages/Login";
import Home from "../pages/Home";
import Cargos from "../pages/Cargos";
import Cidades from "../pages/Cidades";
import Users from "../pages/Users";
import Projetos from "../pages/Projetos";
import SumaryFolha from "../pages/SumaryFolha";

import { AuthContext } from "../providers/AuthProvider";

export const Routes = () => {
  const { user } = useContext(AuthContext);
  if (
    window.location.protocol === "http:" &&
    window.location.hostname !== "localhost"
  ) {
    window.location.href = window.location.href.replace("http:", "https:");
    return null;
  }

  return (
    <RRDRoutes>
      <Route
        path="/colaboradores"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />

      {(user?.typeUser === "admin" || user?.typeUser === "Supervisor") && (
        <>
          <Route
            path="/funcoes"
            element={
              <PrivateRoute>
                <Cargos />
              </PrivateRoute>
            }
          />

          <Route
            path="/cidades"
            element={
              <PrivateRoute>
                <Cidades />
              </PrivateRoute>
            }
          />

          <Route
            path="/projetos"
            element={
              <PrivateRoute>
                <Projetos />
              </PrivateRoute>
            }
          />
        </>
      )}

      {user?.typeUser === "admin" && (
        <>
          <Route
            path="/usuarios"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />

          <Route
            path="/resumo-folha"
            element={
              <PrivateRoute>
                <SumaryFolha />
              </PrivateRoute>
            }
          />
        </>
      )}

      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route path="*" element={<h1>404 Not found</h1>} />
    </RRDRoutes>
  );
};

function PublicRoute({ children }) {
  const { user } = useContext(AuthContext);
  if (user) {
    return <Navigate to="/colaboradores" />;
  } else {
    return children;
  }
}

function PrivateRoute({ children }) {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Navigate to="/" />;
  } else {
    return (
      <AppTheme>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: "flex" }}>
          <SideMenu />
          <AppNavbar />
          {/* Main content */}
          <Box
            component="main"
            sx={(theme) => ({
              flexGrow: 1,
              backgroundColor: theme.vars
                ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                : alpha(theme.palette.background.default, 1),
              overflow: "auto",
            })}
          >
            <Stack
              spacing={2}
              sx={{
                alignItems: "center",
                // mx: 3,
                pb: 10,
                mt: { xs: 8, md: 0 },
              }}
            >
              <Header />
              <MainGrid children={children} />
            </Stack>
          </Box>
        </Box>
      </AppTheme>
    );
  }
}
