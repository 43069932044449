import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { AuthContext } from "../../providers/AuthProvider";
import AddUpdate from "./components/AddUpdate";
import api from "../../utils/api";
import "./Cargos.css";
import Modals from "../../components/Modal";
import { Box, CircularProgress } from "@mui/material";

const Cargos = () => {
  const { reload, setReload } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log(clients);

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/cargos");

      if (data) {
        setClients(data);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
      setReload(false);
    }
  };

  useEffect(() => {
    getClients();
  }, [reload]);

  return (
    <div className="containerFuncionarios">
      <div className="containerHeaderCargos">
        <div className="title">Funções</div>

        <AddUpdate Button={<div className="button">Adicionar</div>} />
      </div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            padding: "40px",
            marginTop: "60px",
          }}
        >
          <CircularProgress sx={{ color: "#187648" }} />
        </Box>
      ) : (
        <div className="containerList">
          {clients
            ?.filter((i) => i.nome !== "INDEFINIDO")
            .map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`card ${idx % 2 !== 0 && "colorBack"}`}
                  style={{ padding: "10px" }}
                >
                  <div className="item">{item?.nome}</div>
                  <Modals
                    buttons={<DeleteIcon sx={{ color: "#d22c38" }} />}
                    title="APAGAR FUNÇÃO"
                    id={item.id}
                    rota="cargos"
                    label={
                      <div>
                        Tem certeza de que deseja apagar a função{" "}
                        <strong>{item?.nome}</strong>? ao confirmar, essa ação
                        não podera ser desfeita.
                      </div>
                    }
                  />
                  <AddUpdate
                    Button={
                      <div className="button">
                        <EditIcon />
                      </div>
                    }
                    value={item}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Cargos;
