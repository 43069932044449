import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./SumaryFolha.css";
import moment from "moment";
import SummaryTable from "./components/SummaryTable";
import SummaryIndividual from "./components/SummaryIndividual";

const mesesCompletos = {
  janeiro: 1,
  fevereiro: 2,
  março: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

const SumaryFolha = () => {
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [citys, setCitys] = useState([]);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    city: null,
    situation: 1,
    year: moment().format("yyyy"),
    month: moment().format("MMMM"),
  });

  const getColabsFilter = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/funcionarios/filters/${user?.id}`, {
        params: {
          cidadeId: filters.city.id,
        },
      });

      if (data) {
        setClients(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCitys = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/cidades");
      if (data) {
        setCitys(data);
      }
    } catch (error) {
      console.error(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCitys();
  }, []);

  const filteredData = clients?.filter((dados) =>
    dados?.historicoSituacao?.some(
      (situacao) =>
        Number(situacao.mes) === Number(mesesCompletos[filters.month]) &&
        Number(situacao.year) === Number(filters.year) &&
        Number(situacao.situacao) === Number(1)
    )
  );

  const handleFilterChange = (field) => (event) => {
    setFilters((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  // const resultado = Object.entries(agrupadosPorProjeto).map(
  //   ([projeto, pessoas]) => ({
  //     projeto,
  //     pessoas,
  //     totalSalario: pessoas.reduce(
  //       (total, pessoa) => total + Number(pessoa.salario),
  //       0
  //     ),
  //     inss: pessoas.reduce(
  //       (total, pessoa) =>
  //         total + calcularValorLiquido(Number(pessoa.salario), pessoa),
  //       0
  //     ),
  //     patronal: pessoas.reduce(
  //       (total, pessoa) => total + patronal(Number(pessoa.salario), pessoa),
  //       0
  //     ),
  //   })
  // );

  return (
    <div className="summary-container">
      <div className="headerSumay">
        <div className="title">RESUMO DA FOLHA</div>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            mb: 3,
            flexWrap: "wrap",
          }}
        >
          <FormControl className="inputSEacrh" size="small" disabled={loading}>
            <InputLabel id="city-label">Cidade</InputLabel>
            <Select
              labelId="city-label"
              label="Cidade"
              value={filters.city}
              onChange={handleFilterChange("city")}
              sx={{ width: "200px" }}
            >
              {citys?.map((city) => (
                <MenuItem key={city.id} value={city}>
                  {city.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="inputSEacrh" size="small" disabled={loading}>
            <InputLabel id="month-year-label">Mês</InputLabel>
            <Select
              labelId="month-year-label"
              label="Mês"
              value={filters.month}
              onChange={handleFilterChange("month")}
              sx={{ width: "200px" }}
            >
              <MenuItem value={"outubro"}>Outubro</MenuItem>
              <MenuItem value={"novembro"}>Novembro</MenuItem>
              <MenuItem value={"dezembro"}>Dezembro</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="inputSEacrh" size="small" disabled={loading}>
            <InputLabel id="month-year-label">Ano</InputLabel>
            <Select
              labelId="month-year-label"
              label="Ano"
              value={filters.year}
              onChange={handleFilterChange("year")}
              sx={{ width: "200px" }}
            >
              <MenuItem key={2024} value={2024}>
                2024
              </MenuItem>
            </Select>
          </FormControl>

          <button onClick={getColabsFilter} className="buttoSEarch">
            <SearchIcon sx={{ width: "20px" }} />
            Buscar
          </button>
        </Box>
      </div>

      <div className="menu-toggle">
        <button
          className={`menu-toggle-btn ${!selected && "selected"}`}
          onClick={() => setSelected(false)}
        >
          Geral
        </button>
        <button
          className={`menu-toggle-btn ${selected && "selected"}`}
          onClick={() => setSelected(true)}
        >
          Individual
        </button>
      </div>
      {selected ? (
        <SummaryIndividual filteredData={filteredData} filters={filters} />
      ) : (
        <SummaryTable filteredData={filteredData} filters={filters} />
      )}
    </div>
  );
};

export default SumaryFolha;
