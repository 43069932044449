import React, { useMemo, useState, useCallback } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// Estilos movidos para fora do componente para evitar recriação
const styles = StyleSheet.create({
  page: {
    padding: 30,
    gap: 20,
  },
  section: {
    flexDirection: "row",
  },
  header: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    gap: "0px",
  },
  subheader: {
    fontSize: 12,
    fontWeight: "bold",
  },
  text: {
    fontSize: 11,
    marginBottom: 3,
  },
  textPeq: {
    fontSize: 8,
    marginBottom: 3,
    fontWeight: "bold",
  },
  signature: {
    marginTop: 5,
    fontSize: 12,
    textAlign: "center",
  },
});

// Constantes movidas para fora para evitar recriação
const mesesCompletos = {
  janeiro: 1,
  fevereiro: 2,
  março: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

// Função de formatação de CPF movida para fora e memoizada
const formatarCPF = (cpf) => {
  if (!cpf || cpf.length !== 10) return "";
  const apenasNumeros = cpf.replace(/\D/g, "");
  if (apenasNumeros.length !== 11) return "";
  return apenasNumeros
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{2})$/, "$1-$2");
};

// Função para calcular valores financeiros
const calcularValores = (dados, valorServico) => {
  const iss = dados?.cidade?.iss === "sim" && dados.tipoPagamento !== "mei" 
    ? valorServico * 0.05 
    : 0;

  const inss = dados?.cidade?.inss === "sim" && dados.tipoPagamento !== "mei"
    ? valorServico * 0.11
    : 0;

  let baseCalculo = inss > 564.8 ? valorServico - inss : valorServico - 564.8;
  
  let irrf = 0;
  if (dados?.cidade?.irrf === "sim" && dados.tipoPagamento !== "mei") {
    if (baseCalculo > 4664.68) {
      irrf = baseCalculo * 0.275 - 896.0;
    } else if (baseCalculo > 3751.05) {
      irrf = baseCalculo * 0.225 - 662.77;
    } else if (baseCalculo > 2826.65) {
      irrf = baseCalculo * 0.15 - 381.44;
    } else if (baseCalculo > 2259.2) {
      irrf = baseCalculo * 0.075 - 169.44;
    }
  }

  const outrosDescontos = 0;
  const valorLiquido = valorServico - iss - inss - irrf - outrosDescontos;

  return { iss, inss, irrf, outrosDescontos, valorLiquido };
};

// Componente de formato de moeda
const FormatMoney = ({ value }) => (
  <Text style={styles.text}>
    {new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value)}
  </Text>
);

// Componente de página do recibo otimizado
const ReciboPDFPage = React.memo(({ dados, data, mes, year, index }) => {
  const valorMes = useMemo(() => 
    dados?.historicoSalarios?.find(
      (salario) =>
        Number(salario.mes) === Number(mesesCompletos[mes]) &&
        Number(salario.year) === Number(year)
    ),
    [dados?.historicoSalarios, mes, year]
  );

  const valorServico = Number(valorMes?.valor || 0);
  
  const valores = useMemo(() => 
    calcularValores(dados, valorServico),
    [dados, valorServico]
  );

  return (
    <Page size="A4" style={styles.page} key={index}>
      {["", ""].map((_, idx) => (
        <View key={idx}>
          {/* ... Resto do JSX mantido igual, mas usando os valores calculados ... */}
          {/* Substitua os cálculos inline pelos valores do objeto 'valores' */}
          <View key={idx}>
           <View key={idx}>
            <View style={styles.header}>
              <View
                style={{
                  flex: 1,
                  border: "1px solid #121212",
                  justifyContent: "center",
                  margin: "0px",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  {dados.tipoPagamento === "rpa"
                    ? "RECIBO DE PAGAMENTO A AUTÔNOMO - RPA"
                    : "RECIBO DE PAGAMENTO"}
                </Text>
              </View>
              <View
                style={{
                  alignItems: "flex-end",
                  border: "1px solid #121212",
                  margin: "0px",
                }}
              >
                <Text style={styles.subheader}>{`${idx + 1}ª VIA`}</Text>
                <View
                  style={{
                    alignItems: "flex-end",
                    borderTop: "1px solid #121212",
                    padding: "3px",
                  }}
                >
                  <Text style={styles.textPeq}>MÊS REFERÊNCIA</Text>
                  <Text style={{ ...styles.text, fontSize: 9 }}>{data}</Text>
                </View>
              </View>
            </View>

            <View style={styles.section}>
              <View style={{ border: "1px solid #121212", flex: 2 }}>
                <Text style={styles.textPeq}>NOME OU RAZÃO SOCIAL</Text>
                <Text style={styles.text}>
                  Instituto Potiguar Social e Educacional
                </Text>
              </View>

              <View style={{ border: "1px solid #121212", flex: 1 }}>
                <Text style={styles.textPeq}>MATRÍCULA (CNPJ)</Text>
                <Text style={styles.text}>17.837.958/0001-34</Text>
              </View>
            </View>

            <View style={styles.section}>
              <View style={{ border: "1px solid #121212", flex: 2 }}>
                <Text style={styles.textPeq}>ENDEREÇO</Text>
                <Text style={styles.text}>
                  Escritório I: Av. Amintas Barros, 3700, Sala 205, lado A,
                  Lagoa Nova, CEP: 59075-810, Natal/RN
                </Text>
                <Text style={styles.text}>
                  Sede: Rua Luiz Lopes, 422, Centro, CEP: 59.184-000, Vera
                  Cruz/RN
                </Text>
              </View>
            </View>

            <View style={styles.section}>
              <View style={{ border: "1px solid #121212", flex: 2 }}>
                <Text style={styles.textPeq}>CONTATO</Text>
                <Text style={styles.text}>
                  e-mail: potiguarinstituto@gmail.com @institutopotiguar
                  telefone/WhatsApp: (84) 99185-4000
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={{ ...styles.section, flex: 1 }}>
                <View style={{ border: "1px solid #121212", flex: 2 }}>
                  <Text
                    style={{
                      borderBottom: "1px solid #121212",
                      fontSize: 12,
                      padding: 8,
                    }}
                  >
                    DADOS PRESTADOR DE SERVIÇO
                  </Text>

                  <View style={{ borderTop: "1px solid #121212" }}>
                    <Text style={styles.textPeq}>NOME</Text>
                    <Text style={styles.text}>{dados.nome}</Text>
                  </View>

                  <View style={{ borderTop: "1px solid #121212" }}>
                    <Text style={styles.textPeq}>CPF</Text>
                    <Text style={styles.text}>{formatarCPF(dados?.cpf || null)}</Text>
                  </View>

                  <View style={{ borderTop: "1px solid #121212" }}>
                    <Text style={styles.textPeq}>
                      Função do Serviço Prestados:
                    </Text>
                    <Text style={styles.text}>{dados.cargo?.nome === "INDEFINIDO" ? "" : dados.cargo?.nome}</Text>
                  </View>
                </View>
              </View>

              <View style={{ ...styles.section, flex: 1 }}>
                <View style={{ border: "1px solid #121212", flex: 2 }}>
                  <Text
                    style={{
                      borderBottom: "1px solid #121212",
                      fontSize: 12,
                      padding: 8,
                    }}
                  >
                    DISCRIMINAÇÃO:
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={styles.text}>
                      1 - Valor do Serviço Prestados:
                    </Text>
                    <Text style={styles.text}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valorMes?.valor)}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={styles.text}>2 - ISS:</Text>
                    <Text style={styles.text}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valores?.iss)}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={styles.text}>3 - INSS:</Text>
                    <Text style={styles.text}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valores?.inss)}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={styles.text}>4 - IRPF:</Text>
                    <Text style={styles.text}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valores?.irrf)}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={styles.text}>5 - Outros Descontos:</Text>
                    <Text style={styles.text}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valores?.outrosDescontos)}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderTop: "1px solid #121212",
                    }}
                  >
                    <Text style={styles.text}>Valor Líquido:</Text>
                    <Text style={styles.text}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(valores?.valorLiquido)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                border: "1px solid #121212",
                flexDirection: "column",
                padding: "10px",
                gap: "8px",
              }}
            >
              <Text
                style={{ ...styles.text, textAlign: "justify" }}
              >{` Recebi, da empresa acima identificada, a importânica de ${new Intl.NumberFormat(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ).format(valores?.valorLiquido)} conforme discriminado acima.`}</Text>

              <Text
                style={{ ...styles.text, alignSelf: "flex-end" }}
              >{`${dados?.cidade?.nome}/RN ________/___________/__________`}</Text>
              <View style={styles.signature}>
                <Text style={styles.text}>
                  ____________________________________
                </Text>
                <Text style={styles.text}>Assinatura Prestador de Serviço</Text>
              </View>
            </View>
          </View>
        </View>
        </View>
      ))}
    </Page>
  );
});

// Componente do documento PDF otimizado
const ReciboPDF = React.memo(({ listaDeDados, data, mes, year }) => {
  const filteredData = useMemo(() => 
    listaDeDados?.filter((dados) =>
      dados?.historicoSalarios?.some(
        (salario) =>
          Number(salario.mes) === Number(mesesCompletos[mes]) &&
          Number(salario.year) === Number(year)
      )
    ),
    [listaDeDados, mes, year]
  );

  return (
    <Document>
      {filteredData?.map((dadosFiltrados, index) => (
        <ReciboPDFPage
          dados={dadosFiltrados}
          data={data}
          mes={mes}
          year={year}
          key={index}
        />
      ))}
    </Document>
  );
});

// Componente principal otimizado com lazy loading
const PdfGenerator = ({ listaDeDados, data, mes, year }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  
  const dadosFiltrados = useMemo(() => 
    listaDeDados?.filter((dados) =>
      dados?.historicoSalarios?.some(
        (salario) =>
          Number(salario.mes) === Number(mesesCompletos[mes]) &&
          Number(salario.year) === Number(year)
      )
    ),
    [listaDeDados, mes, year]
  );

  const handleClick = useCallback(() => {
    setIsGenerating(true);
  }, []);

  if (dadosFiltrados?.length === 0) {
    return (
      <div
        style={{
          padding: "5px 10px",
          flex: 1,
          backgroundColor: "#A1ADAD",
          borderRadius: "10px",
          textAlign: "center",
          fontWeight: "600",
          cursor: "not-allowed",
          color: "#899595",
        }}
      >
        Baixar Recibo
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "5px 10px",
        flex: 1,
        backgroundColor: "#188754",
        borderRadius: "10px",
        textAlign: "center",
        fontWeight: "600",
        cursor: "pointer",
      }}
    >
      {isGenerating ? (
        <PDFDownloadLink
          document={
            <ReciboPDF
              listaDeDados={dadosFiltrados}
              data={data}
              mes={mes}
              year={year}
            />
          }
          fileName={`${mes}-${year}.pdf`}
          style={{
            textDecoration: "none",
            textTransform: "none",
            color: "#fafafa",
          }}
        >
          {({ loading }) => (loading ? "Gerando recibos..." : "Baixar Recibos")}
        </PDFDownloadLink>
      ) : (
        <button
          onClick={handleClick}
          style={{
            background: "none",
            border: "none",
            color: "#fafafa",
            cursor: "pointer",
            width: "100%",
            padding: "0",
            fontWeight: "600",
          }}
        >
          Gerar Recibos
        </button>
      )}
    </div>
  );
};

export default React.memo(PdfGenerator);