import * as React from 'react';
import Box from '@mui/material/Box';
import Copyright from './Copyright';

export default function MainGrid({children}) {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {children}
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}