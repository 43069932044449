import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Box,
} from "@mui/material";
import Modal from "@mui/material/Modal";

const columnOptions = [
  { value: "nome", label: "Nome" },
  { value: "cpf", label: "CPF" },
  { value: "chavePix", label: "Pix" },
  { value: "cidade", label: "Cidade" },
  { value: "tipo_pagamento", label: "Vinculo" },
  { value: "salarioBruto", label: "Salário Bruto____" },
  { value: "salarioLiquido", label: "Salário Líquido__" },
  { value: "banco", label: "Banco" },
  { value: "agencia", label: "Agência" },
  { value: "conta", label: "Conta" },
  { value: "inss", label: "INSS_____" },
  { value: "irpf", label: "IRPF_____" },
  { value: "inssPatronal", label: "INSS PATRONAL" },
  { value: "gt", label: "Gestão técnica___" },
  { value: "custoTotal", label: "Custo total_____" },
];

function isMobileDevice() {
  return window.innerWidth <= 768;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobileDevice() ? "80vw" : "600px",
  height: "250px",
  bgcolor: "#f9f9f9",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  maxHeight: "80vh",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const ListaFuncionariosPDF = ({ funcionarios }) => {
  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    columnOptions.map((option) => option.value)
  ); // Selecionar todas as colunas inicialmente

  function formatToBRL(value) {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const sortFuncionarios = (funcionarios) => {
    return funcionarios.sort((a, b) => {
      // Ordenação pelo nome
      const nomeA = a.nome?.toUpperCase() || "";
      const nomeB = b.nome?.toUpperCase() || "";
  
      // Ordenação pelo vínculo
      const vinculoA = a.tipoPagamento?.toUpperCase() || "";
      const vinculoB = b.tipoPagamento?.toUpperCase() || "";
  
      // Ordenação pela cidade
      const cidadeA = a.cidade?.nome?.toUpperCase() || "";
      const cidadeB = b.cidade?.nome?.toUpperCase() || "";
  
      // Ordenação pelo banco
      const bancoA = a.banco?.toUpperCase() || "";
      const bancoB = b.banco?.toUpperCase() || "";
  
      // Comparação ordenada pelos critérios especificados



      if (vinculoA < vinculoB) return -1;
      if (vinculoA > vinculoB) return 1;
      if (nomeA < nomeB) return -1;
      if (nomeA > nomeB) return 1;
      if (bancoA < bancoB) return -1;
      if (bancoA > bancoB) return 1;
      if (cidadeA < cidadeB) return -1;
      if (cidadeA > cidadeB) return 1;

      return 0;
    });
  };

  function calcularValorLiquido(salario, dados) {
    const valorServico = Number(salario);
    const iss =
      dados?.cidade?.iss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.05
        : 0; // ISS (5%)
    const inss =
      dados?.cidade?.inss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.11
        : 0; // INSS (11%)

    let baseCalculo = inss > 564.8 ? valorServico - inss : valorServico - 564.8;
    let irrf = 0;

    if (
      baseCalculo > 4664.68 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.275 - 896.0; // 27,5%
    } else if (
      baseCalculo > 3751.05 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.225 - 662.77; // 22,5%
    } else if (
      baseCalculo > 2826.65 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.15 - 381.44; // 15%
    } else if (
      baseCalculo > 2259.2 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.075 - 169.44; // 7,5%
    }

    const outrosDescontos = 0; // Outros descontos
    const valorLiquido = valorServico - iss - inss - irrf - outrosDescontos;

    return formatToBRL(valorLiquido);
  }

  function calcularIRPF(salario, dados) {
    const valorServico = Number(salario);

    const inss =
      dados?.cidade?.inss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.11
        : 0;
    const baseCalculo =
      inss > 564.8 ? valorServico - inss : valorServico - 564.8;

    let irrf = 0;
    if (
      baseCalculo > 4664.68 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.275 - 896.0; // 27,5%
    } else if (
      baseCalculo > 3751.05 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.225 - 662.77; // 22,5%
    } else if (
      baseCalculo > 2826.65 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.15 - 381.44; // 15%
    } else if (
      baseCalculo > 2259.2 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.075 - 169.44; // 7,5%
    }

    return irrf;
  }

  const generatePDF = () => {
    const doc = new jsPDF("landscape");

    // Definir o título do PDF
    doc.text("Colaboradores", 14, 10);

    // Texto alinhado à direita
    const text = `Total: ${funcionarios.length}`;
    const pageWidth = doc.internal.pageSize.width;
    const textWidth = doc.getTextWidth(text);
    doc.text(text, pageWidth - textWidth - 14, 10);

    // Estruturar as colunas e os dados
    const columns = [];
    const rows = [];

    selectedColumns.forEach((column) => {
      const option = columnOptions.find((option) => option.value === column);
      if (option) columns.push(option.label);
    });

    // Ordenar os dados antes de gerar o PDF
    const sortedFuncionarios = sortFuncionarios(funcionarios);

    sortedFuncionarios.forEach((funcionario) => {
      const row = [];
      selectedColumns.forEach((column) => {
        if (column === "nome") row.push(funcionario.nome);
        if (column === "cpf") row.push(funcionario.cpf);
        if (column === "chavePix") row.push(funcionario.chavePix);
        if (column === "cidade") row.push(funcionario.cidade?.nome || "N/A");
        if (column === "tipo_pagamento")
          row.push(funcionario?.tipoPagamento?.toUpperCase() || "N/A");
        if (column === "salarioBruto")
          row.push(formatToBRL(Number(funcionario.salario)) || "R$ 0,00");
        if (column === "salarioLiquido")
          row.push(
            calcularValorLiquido(funcionario.salario, funcionario) || "R$ 0,00"
          );
        if (column === "banco") row.push(funcionario.banco || "N/A");
        if (column === "agencia") row.push(funcionario.agencia || "N/A");
        if (column === "conta") row.push(funcionario.conta || "N/A");
        if (column === "inss")
          row.push(
            formatToBRL(
              funcionario?.cidade?.inss === "sim" &&
                funcionario.tipoPagamento !== "mei"
                ? Number(funcionario.salario) * 0.11
                : "R$ 0,00"
            )
          );
        if (column === "irpf")
          row.push(
            formatToBRL(calcularIRPF(funcionario.salario, funcionario)) ||
              "R$ 0,00"
          );
        if (column === "inssPatronal")
          row.push(
            formatToBRL(
              Number(
                funcionario.tipoPagamento !== "mei" && funcionario.salario * 0.2
              ) || "R$ 0,00"
            )
          );
        if (column === "gt")
          row.push(
            formatToBRL(
              Number(
                Number(funcionario.salario) * (funcionario?.cidade?.gt / 100)
              )
            ) || "R$ 0,00"
          );
        if (column === "custoTotal")
          row.push(
            formatToBRL(
              Number(funcionario.salario) +
                Number(
                  funcionario.tipoPagamento !== "mei" &&
                    funcionario.salario * 0.2
                ) +
                Number(
                  Number(funcionario.salario) * (funcionario?.cidade?.gt / 100)
                )
            ) || "R$ 0,00"
          );
      });
      rows.push(row);
    });

    // Adicionar a tabela ao PDF
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: "grid",
      headStyles: {
        fillColor: [24, 135, 84],
        textColor: [255, 255, 255],
        fontStyle: "bold",
        fontSize: 7,
      },
      bodyStyles: {
        fontSize: 7, // Reduz o tamanho da fonte do corpo da tabela
      },
      margin: { left: 10, right: 10 }, // Define margens menores
      // tableWidth: 'auto',
      // columnStyles: {
      //   0: { cellWidth: 40 }, // Exemplo: primeira coluna com mais largura, ajustada para o conteúdo
      //   1: { cellWidth: 20 },
      //   2: { cellWidth: 20 },
      //   3: { cellWidth: 15 },
      //   4: { cellWidth: 20 },
      //   5: { cellWidth: 20 },
      //   6: { cellWidth: 20 },
      //   7: { cellWidth: 20 },
      //   8: { cellWidth: 20 },
      //   9: { cellWidth: 20 },
      //   10: { cellWidth: 20 },
      //   11: { cellWidth: 20 },
      //   12: { cellWidth: 20 },
      //   13: { cellWidth: 20 },
      // },
    });

    // Salvar o PDF
    doc.save("colaboradores.pdf");
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div style={{ display: "flex" }}>
      <Tooltip
        title="Baixar PDF"
        onClick={handleOpen}
        style={{ fontSize: "15px" }}
      >
        <IconButton>
          PDF
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2>Baixar PDF</h2>

          <div className="form-group" style={{ margin: "0px" }}>
            <label>SELECIONAR COLUNAS:</label>
            <FormControl
              className="form-group"
              style={{ width: "100%", margin: "0px" }}
            >
              <Select
                labelId="demo-multiple-checkbox-label"
                multiple
                value={selectedColumns}
                onChange={handleChange}
                className="formSx"
                renderValue={(selected) => selected.join(", ")}
                sx={{
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                  "&.Mui-focused": {
                    border: "none",
                  },
                }}
              >
                {columnOptions.map((option, idx) => (
                  <MenuItem value={option.value} key={idx}>
                    <Checkbox
                      checked={selectedColumns.indexOf(option.value) > -1}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <button type="submit" className="submit-btn" onClick={generatePDF}>
            Baixar
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default ListaFuncionariosPDF;
