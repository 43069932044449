import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField, 
  Typography, 
  Box,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';

const mesesCompletos = {
  janeiro: 1,
  fevereiro: 2,
  março: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

const SummaryIndividual = ({filteredData, filters}) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter employees based on search term
  const filteredEmployees = filteredData?.filter(employee => 
    employee?.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function calcularValorLiquido(salario, dados) {
    const valorServico = Number(salario);

    const iss =
      dados?.cidade?.iss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.05
        : 0; // ISS (5%)
    const inss =
      dados?.cidade?.inss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.11
        : 0; // INSS (11%)

    let baseCalculo = inss > 564.8 ? valorServico - inss : valorServico - 564.8;

    let irrf = 0;

    if (
      baseCalculo > 4664.68 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.275 - 896.0; // 27,5%
    } else if (
      baseCalculo > 3751.05 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.225 - 662.77; // 22,5%
    } else if (
      baseCalculo > 2826.65 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.15 - 381.44; // 15%
    } else if (
      baseCalculo > 2259.2 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.075 - 169.44; // 7,5%
    }

    const outrosDescontos = 0; // Outros descontos
    const valorLiquido = valorServico - iss - inss - irrf - outrosDescontos;

    return valorLiquido;
  }

  const checkSalary = (historicoSalarios) => {
    const salary =  historicoSalarios.find(
        (salario) =>
          salario.mes === mesesCompletos[filters.month] &&
          salario.year === parseInt(filters.year, 10) );
    return salary?.valor || 0;
  }

  // Calculate total salaries
  const { totalSalarioBruto, totalSalarioLiquido } = useMemo(() => {
    if (!filteredEmployees) return { totalSalarioBruto: 0, totalSalarioLiquido: 0 };

    return filteredEmployees.reduce((acc, employee) => {
      const salarioBruto = checkSalary(employee?.historicoSalarios);
      const salarioLiquido = calcularValorLiquido(salarioBruto, employee);

      return {
        totalSalarioBruto: acc.totalSalarioBruto + salarioBruto,
        totalSalarioLiquido: acc.totalSalarioLiquido + salarioLiquido
      };
    }, { totalSalarioBruto: 0, totalSalarioLiquido: 0 });
  }, [filteredEmployees, filters]);

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      {/* Search Input */}
      <TextField 
        fullWidth
        variant="outlined"
        label="Buscar por nome"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: 2 }}
      />

      {/* Employee Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Vinculo</TableCell>
              <TableCell>Projeto</TableCell>
              <TableCell align="right">Salário</TableCell>
              <TableCell align="right">Salário liquido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEmployees
            .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os funcionários por nome
            .map((employee, idx) => (
              <TableRow key={employee.id}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{employee.nome}</TableCell>
                <TableCell>{employee.cpf}</TableCell>
                <TableCell>{employee.tipoPagamento}</TableCell>
                <TableCell>{employee.projeto}</TableCell>
                <TableCell align="right">
                  {new Intl.NumberFormat('pt-BR', { 
                    style: 'currency', 
                    currency: 'BRL' 
                  }).format(checkSalary(employee?.historicoSalarios))}
                </TableCell>
                <TableCell align="right">
                  {new Intl.NumberFormat('pt-BR', { 
                    style: 'currency', 
                    currency: 'BRL' 
                  }).format(calcularValorLiquido(checkSalary(employee?.historicoSalarios), employee))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* No Results Handling */}
      {filteredEmployees.length === 0 && (
        <Typography 
          variant="body2" 
          color="textSecondary" 
          align="center" 
          sx={{ marginTop: 2 }}
        >
          Nenhum funcionário encontrado
        </Typography>
      )}

      {/* Total Salary Summary */}
      {filteredEmployees.length > 0 && (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined">
              <CardContent sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between' 
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AttachMoneyIcon color="primary" sx={{ marginRight: 1 }} />
                  <Typography variant="subtitle1">Total Salário Bruto</Typography>
                </Box>
                <Typography variant="h6" color="primary">
                  {new Intl.NumberFormat('pt-BR', { 
                    style: 'currency', 
                    currency: 'BRL' 
                  }).format(totalSalarioBruto)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined">
              <CardContent sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between' 
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ReceiptIcon color="secondary" sx={{ marginRight: 1 }} />
                  <Typography variant="subtitle1">Total Salário Líquido</Typography>
                </Box>
                <Typography variant="h6" color="secondary">
                  {new Intl.NumberFormat('pt-BR', { 
                    style: 'currency', 
                    currency: 'BRL' 
                  }).format(totalSalarioLiquido)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SummaryIndividual;