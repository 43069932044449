import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";

import "./AddUpdate.css";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { toast } from "react-toastify";

function isMobileDevice() {
  return window.innerWidth <= 768;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobileDevice() ? '80vw' : '600px',
  height: '500px',
  bgcolor: "#f9f9f9",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  maxHeight: "80vh",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const CidadesSelect = () => {
  const { reload , setReload} = useContext(AuthContext);
  const [citys, setCitys] = useState([]);

  const { values, setFieldValue } = useFormikContext();

  const handleCargoChange = (event) => {
    setFieldValue("typeUser", event.target.value);
  };

  const getCitys = async () => {
    try {
      const { data } = await api.get("/cidades");

      if (data) {
        setCitys(data);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setReload(false);
    }
  };

  useEffect(() => {
    getCitys();
  }, [reload]);

  return (
    <div className="form-group">
      <label>Permissão</label>
      <select
        onChange={handleCargoChange}
        className="form-control"
        name={"typeUser"}
        value={values.typeUser || ""}
      >
        <option value="">Selecione</option>
        <option value="admin">Admin</option>
        <option value="Supervisor">Supervisor</option>
        {citys?.map((carg, id) => (
          <option key={id} value={carg.id} style={{ cursor: "pointer" }}>
            {carg.nome}
          </option>
        ))}
      </select>
      <ErrorMessage name={"typeUser"} component="div" className="error-message" />
    </div>
  );
};

const AddUpdate = ({ Button, value }) => {
  const { setReload } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    // nome: Yup.string()
    //   .required("O nome da cidade é obrigatório")
    //   .min(2, "O nome da cidade deve ter pelo menos 2 caracteres")
    //   .max(100, "O nome da cidade deve ter no máximo 100 caracteres"),

    // typeUser: Yup.string().required("O typeUser é obrigatório"),
  });

  const initialValues = {
    nome: value?.nome || "",
    typeUser: value?.typeUser || "",
    cpf: value?.cpf,
    password: null,
  };

  const saveUser = async (values) => {
    setLoading(true);
    
    try {
      if (value?.id) {
        // Se o ID existir, faz o update
        const { data } = await api.put(`/register/${value?.id}`, {
          ...values,
        });
        toast.success("usuario atualizado com sucesso.");
        setOpen(false);
        return data; // Retorna os dados atualizados
      } else {
        // Se o ID não existir, faz a criação
        const { data } = await api.post("/register", { ...values });

        toast.success("usuario criado com sucesso.");
        return data; // Retorna os dados do novo funcionário
      }
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível salvar o usuario.");
    } finally {
      setLoading(false);
      setReload(true);
    }
  };

  const handleSubmit = async (values) => {
    await saveUser(values);
    // console.log(dadosFiltrados);
  };

  // const selectedCargo = listCidades?.find((carg) => Number(carg.id) === Number(values.id)).typeUser;

  return (
    <div>
      <div onClick={handleOpen}>{Button}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values); // Chama a função de envio
              resetForm(); // Reseta o formulário para os valores iniciais
            }}
          >
            {() => (
              <Form className="form-container">
                <h2>{value ? "Editar usuario" : "Cadastrar usuario"}</h2>
                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Nome</label>
                    <Field name="nome" type="text" />
                    <ErrorMessage
                      name="nome"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Cpf</label>
                    <Field name="cpf" type="text" />
                    <ErrorMessage
                      name="cpf"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Senha</label>
                    <Field name="password" type="password" />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                <Field component={CidadesSelect} />

                <button type="submit" className="submit-btn">
                  {loading ? "Salvando" : "Salvar"}
                </button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdate;
