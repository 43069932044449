import React, { useContext, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import AppTheme from "../../theme/shared-theme/AppTheme";

import { AuthContext } from "../../providers/AuthProvider";
import Logo from "../../assets/Logo.png";
import "./Login.css";
import { useFormik } from "formik";
import * as yup from "yup";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "100vh", // Ocupa toda a altura da tela
  position: "relative", // Para que o ::before funcione corretamente
  padding: 20,
  justifyContent: "center", // Centraliza verticalmente
  alignItems: "center", // Centraliza horizontalmente
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

const Login = (props) => {
  const { signIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const login = async (values) => {
    const { cpf, password } = values;
    setLoading(true);
    try {
      await signIn(cpf, password);
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  // const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

  const form = useFormik({
    initialValues: {
      cpf: "",
      password: "",
    },

    validationSchema: yup.object({
      cpf: yup
        .number()
        .min(11, "o CPF deve conter 11 caracteres.")
        .required("CPF é obrigatório!"),
      password: yup
        .string()
        .min(8, "A senha deve conter pelo menos 8 caracteres.")
        .required("Senha obrigatória!"),
    }),

    onSubmit: (values) => login(values),
  });

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <div className="errorINputRegi">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
              }}
            >
              <img src={Logo} alt="logo" />
            </div>
            <div className="containerInputs">
              <div
                style={{
                  marginBottom: "6px",
                  fontSize: "13px",
                  marginLeft: "5px",
                }}
              >
                CPF
              </div>
              <input
                className="inputLogin"
                placeholder={"CPF"}
                style={{ textIndent: "10px" }}
                type={"number"}
                onChange={(e) => {
                  form.setFieldValue("cpf", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("cpf", true);
                }}
              />
              {form.touched.cpf && form.errors.cpf && (
                <Typography
                  style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
                  color={"red"}
                >
                  {form.errors.cpf}
                </Typography>
              )}
            </div>

            <div className="errorINputRegi">
              <div
                style={{
                  marginBottom: "6px",
                  fontSize: "13px",
                  marginLeft: "5px",
                }}
              >
                Senha
              </div>
              <input
                style={{ textIndent: "10px" }}
                className="inputLogin"
                placeholder={"Password"}
                type={"password"}
                onChange={(e) => {
                  form.setFieldValue("password", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("password", true);
                }}
              />
              {form.touched.password && form.errors.password && (
                <Typography
                  style={{ fontSize: 14, marginLeft: 5, marginTop: 5 }}
                  color={"red"}
                >
                  {form.errors.password}
                </Typography>
              )}
            </div>

            <div className="containerLoginForgot" style={{ marginTop: "15px" }}>
              <button
                type="submit"
                onClick={() => {
                  form.handleSubmit();
                }}
                className="buttonLogin"
              >
                {loading ? "Loading..." : "Entrar"}
              </button>
            </div>
          </div>
        </Card>
      </SignInContainer>
    </AppTheme>
  );
};

export default Login;
