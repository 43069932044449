import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import "./AddUpdate.css";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import { toast } from "react-toastify";

function isMobileDevice() {
  return window.innerWidth <= 768;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobileDevice() ? '80vw' : '600px',
  height: '270px',
  bgcolor: "#f9f9f9",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  maxHeight: "80vh",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const AddUpdate = ({ Button, value }) => {
  const { setReload } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    nome: Yup.string(),
  });

  const initialValues = {
    nome: value?.nome || "",
  };

  const saveCargo = async (values) => {
    setLoading(true);
    const dadosFiltrados = Object.entries(values)
      .map(([key, value]) => [key, value === "" ? null : value]) // Substitui strings vazias por null
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    try {
      if (value?.id) {
        // Se o ID existir, faz o update
        const { data } = await api.put(`/cargos/${value?.id}`, {
          ...dadosFiltrados,
        });
        toast.success("função atualizada com sucesso.");
        return data; // Retorna os dados atualizados
      } else {
        // Se o ID não existir, faz a criação
        const { data } = await api.post("/cargos", { ...dadosFiltrados });

        toast.success("função criada com sucesso.");
        return data; // Retorna os dados do novo funcionário
      }
    } catch (error) {
      console.error(error);
      toast.error("Não foi possível salvar a função.");
    } finally {
      setLoading(false);
      setReload(true);
    }
  };

  const handleSubmit = async (values) => {
    await saveCargo(values);
    // console.log(dadosFiltrados);
  };

  // const selectedCargo = listCidades?.find((carg) => Number(carg.id) === Number(values.id)).estado;

  return (
    <div>
      <div onClick={handleOpen}>{Button}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values); // Chama a função de envio
              resetForm(); // Reseta o formulário para os valores iniciais
            }}
          >
            {() => (
              <Form className="form-container">
                <h2>{value ? "Editar função" : "Cadastro de função"}</h2>
                <div className="containerJuntos">
                  <div className="form-group">
                    <label>Nome</label>
                    <Field name="nome" type="text" />
                    <ErrorMessage
                      name="nome"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <button type="submit" className="submit-btn">
                  {loading ? "Salvando" : "Salvar"}
                </button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdate;
