import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

import { AuthContext } from "../providers/AuthProvider";
import api from "../utils/api";
import { toast } from "react-toastify";


function isMobileDevice() {
  return window.innerWidth <= 768;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobileDevice() ? '80vw' : '500px',
  // height: isMobileDevice() ? '350px' : '250px',
  padding: '20px 0px',
  bgcolor: "#f9f9f9",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const Modals = ({ buttons, title, id, rota, label }) => {
  const { setReload } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteItem = async () => {
    setLoading(true);
    try {
      await api.delete(`/${rota}/${id}`);
      toast.success(`Item apagado com sucesso`);
    } catch (error) {
      toast.error(`Não foi possível apagar item.`);
    } finally {
      setLoading(false);
      setReload(true);
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleOpen}>{buttons}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2 style={{ color: "#dd3545" }}>{title}</h2>
          <div>{label}</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                padding: "5px 10px",
                flex: 1,
                border :' 1px solid #626262',
                borderRadius: "10px",
                textAlign: "center",
                color: "#626262",
                fontWeight: "600",
                cursor :'pointer',
              }}
              onClick={handleClose}
            >
              Cancelar
            </div>
            <div
              style={{
                padding: "5px 10px",
                flex: 1,
                backgroundColor: "#fe2f39",
                borderRadius: "10px",
                textAlign: "center",
                color: "#fafafa",
                fontWeight: "600",
                cursor :'pointer',
              }}
              onClick={() => deleteItem()}
            >
             {loading ? 'Apagando' : 'Apagar'}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Modals;
