import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiToolbar from '@mui/material/Toolbar';
import { tabsClasses } from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SideMenuMobile from './SideMenuMobile';
import MenuButton from './MenuButton';
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useLocation } from 'react-router-dom';

const Toolbar = styled(MuiToolbar)({
  width: '100%',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  gap: '12px',
  flexShrink: 0,
  [`& ${tabsClasses.flexContainer}`]: {
    gap: '8px',
    p: '8px',
    pb: 0,
  },
});

export default function AppNavbar() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const nomePagina = location.pathname.split("/").filter(Boolean).pop() || "home";
  
  const getNameIcon = (page) => {
    switch (page) {
      case "funcoes":
        return { name: "Cargos", icon: <AnalyticsRoundedIcon /> };
      case "projetos":
        return { name: "Projetos", icon: <ManageAccountsIcon /> };
      case "cidades":
        return { name: "Cidades", icon: <LocationOnIcon /> };
        case "usuarios":
          return { name: "Usuarios", icon: <GroupAddIcon /> };
      default:
        return { name: "Colaboradores", icon: <PeopleRoundedIcon /> };
    }
  };

 function CustomIcon() {
    return (
      <Box
        sx={{
          width: '1.5rem',
          height: '1.5rem',
          bgcolor: '#47cc73',
          borderRadius: '999px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          // backgroundImage:
          //   'linear-gradient(135deg, hsl(210, 98%, 60%) 0%, hsl(210, 100%, 35%) 100%)',
          // color: 'hsla(210, 100%, 95%, 0.9)',
          // border: '1px solid',
          // borderColor: 'hsl(210, 100%, 55%)',
          // boxShadow: 'inset 0 2px 5px rgba(255, 255, 255, 0.3)',
        }}
      >
        {getNameIcon(nomePagina).icon}
      </Box>
    );
  }


  return (
    <AppBar
      position="fixed"
      sx={{
        display: { xs: 'auto', md: 'none' },
        boxShadow: 0,
        bgcolor: 'background.paper',
        backgroundImage: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider',
        top: 'var(--template-frame-height, 0px)',
      }}
    >
      <Toolbar variant="regular">
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <CustomIcon />
            <Typography variant="h5" component="h1" sx={{ color: 'text.primary' }}>
              {getNameIcon(nomePagina).name}
            </Typography>
          </Stack>
          <MenuButton aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuRoundedIcon />
          </MenuButton>
          <SideMenuMobile open={open} toggleDrawer={toggleDrawer} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
