import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, IconButton, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import PdfGenerator from "../../../components/PdfGenerator";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

function isMobileDevice() {
  return window.innerWidth <= 768;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobileDevice() ? "80vw" : "500px",
  // height: isMobileDevice() ? '350px' : '250px',
  padding: "20px 0px",
  bgcolor: "#f9f9f9",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const mesesCompletos = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

const salarios = [
  { mes: 1, year: 2024 },
  { mes: 2, year: 2024 },
  { mes: 3, year: 2024 },
  {mes: 4, year: 2024 },
  { mes: 5, year: 2024 },
  { mes: 6, year: 2024 },
  { mes: 7, year: 2024 },
  { mes: 8, year: 2024 },
  { mes: 9, year: 2024 },
  { mes: 10, year: 2024 },
  { mes: 11, year: 2024 },
  {  mes: 12, year: 2024 },
];

const ReciboModal = ({ filteredRows }) => {
  const [open, setOpen] = useState(false);
  const [mes, setMes] = useState(moment().format("MMMM"));
  const [year, setYear] = useState(moment().format("yyyy"));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMes = (event) => {
    setMes(event.target.value);
  };

  const handleYear = (event) => {
    setYear(event.target.value);
  };

  const getMesesDisponiveis = () => {
    const mesesDisponiveis = salarios
      .filter((salario) => salario.year.toString() === year) // Filtra pelo ano selecionado
      .map((salario) => mesesCompletos[salario.mes - 1]) // Converte os meses para texto
      .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicatas

    return mesesDisponiveis;
  };

  const valueSalario = () => {
    const valu = salarios.find((it) => mesesCompletos[it.mes - 1] === mes && Number(it.year) === Number(year))

    return valu
  }

  return (
    <>
      <Tooltip title="Baixar" onClick={handleOpen}>
        <IconButton style={{ fontSize: "15px" }}>
          RECIBO
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2 style={{ color: "#188754" }}>GERAR RECIBO DE PAGAMENTO</h2>
          {/* <div>{label}</div> */}

          <div style={{ display: "flex", gap: "20px" }}>
            <div className="form-group">
              <label>Mês</label>
              <select
                onChange={handleMes}
                className="form-control"
                name={"mes"}
                value={mes}
              >
                <option value="">Selecione</option>
                {/* {getMesesDisponiveis?.map((me, id) => (
                  <option key={id} value={me} style={{ cursor: "pointer" }}>
                    {me}
                  </option>
                ))} */}
                {getMesesDisponiveis().map((m, index) => (
                  <option key={index} value={m} style={{ cursor: "pointer" }}>
                    {m}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Ano</label>
              <select
                onChange={handleYear}
                className="form-control"
                name={"ano"}
                value={year}
              >
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                padding: "5px 10px",
                flex: 1,
                border: " 1px solid #626262",
                borderRadius: "10px",
                textAlign: "center",
                color: "#626262",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              Cancelar
            </div>

            <PdfGenerator
              listaDeDados={filteredRows}
              salarioValue={valueSalario()?.salario}
              data={`${mes} de ${year}`}
              mes={mes}
              year={year}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ReciboModal;
