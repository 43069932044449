/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import api from "../utils/api";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authData, setAuthData] = useState(null);
  const [reload, setReload] = useState(false);
  const [reloadColab, setReloadColab] = useState(false); 

  const signIn = async (cpf, password) => {
    try {
      const { data } = await api.post("/login", {
        cpf,
        password,
      });

      localStorage.setItem("@potiguaRh: user", JSON.stringify(data));

      api.defaults.headers["Authorization"] = `Bearer ${data.token}`;

      setAuthData(data);

      toast.success("Login realizado com sucesso! Bem-vindo de volta!");
    } catch (error) {
      console.log(error);
      // console.log(error?.response?.data?.error);
      if (error?.response?.data) {
        toast.error(error?.response?.data);
      } else {
        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors[0]?.title
        ) {
          toast.error(`${error.response.data.errors[0].title}`);
        } else {
          toast.error("Por favor, entre em contato com o administrador do sistema.");
        }
      }
    }
  };

  const signOut = async () => {
    localStorage.removeItem("@potiguaRh: user");
    api.defaults.headers["Authorization"] = undefined;

    setAuthData(null);
    navigate('/');
  };

  useEffect(() => {
    const userStorage = localStorage.getItem("@potiguaRh: user");

    if (userStorage) {
      const parsedUser = JSON.parse(userStorage);

      // Checking if the token has expired.
      if (parsedUser.expiresAt < new Date()) {
        signOut();
      } else {
        api.defaults.headers["Authorization"] = `Bearer ${parsedUser.token}`;
        setAuthData(parsedUser);
      }
    } else {
      signOut();
    }
  }, []);

  const signOutIfTokenExpired = async () => {
    const user = await localStorage.getItem("@potiguaRh: user");
    // fetchAddresses();
    if (user) {
      const parsedUser = JSON.parse(user);

      if (moment(parsedUser.expiresAt).isBefore(new Date())) {
        signOut();
      } else {
        api.defaults.headers["Authorization"] = `Bearer ${parsedUser.token}`;
        setAuthData(parsedUser);
      }
    } else {
      signOut();
    }
  };

  useEffect(() => {
    signOutIfTokenExpired();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: authData,
        setAuthData,
        reload,
        setReload,
        reloadColab,
        setReloadColab,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
