import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
// import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
// import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { useNavigate, useLocation } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { AuthContext } from "../providers/AuthProvider";

const mainListItems = [
  {
    text: "Colaboradores",
    icon: <PeopleRoundedIcon sx={{ color: "#167640" }} />,
    path: "/colaboradores",
    type: "public",
  },
  {
    text: "Funções",
    icon: <AnalyticsRoundedIcon sx={{ color: "#167640" }} />,
    path: "/funcoes",
    type: ["admin", "Supervisor"],
  },
  {
    text: "Projetos",
    icon: <ManageAccountsIcon sx={{ color: "#167640" }} />,
    path: "/projetos",
    type: ["admin", "Supervisor"],
  },
  {
    text: "Cidades",
    icon: <LocationOnIcon sx={{ color: "#167640" }} />,
    path: "/cidades",
    type: ["admin", "Supervisor"],
  },
  {
    text: "Usuarios",
    icon: <GroupAddIcon sx={{ color: "#167640" }} />,
    path: "/usuarios",
    type: ["admin"],
  },

  {
    text: "Resumo folha",
    icon: <RequestQuoteIcon sx={{ color: "#167640" }} />,
    path: "/resumo-folha",
    type: ["admin"],
  },
  // { text: 'Tasks', icon: <AssignmentRoundedIcon /> },
];

// const secondaryListItems = [
//   { text: 'Settings', icon: <SettingsRoundedIcon /> },
//   { text: 'About', icon: <InfoRoundedIcon /> },
//   { text: 'Feedback', icon: <HelpRoundedIcon /> },
// ];

export default function MenuContent() {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [pageAtual, setPageAtual] = React.useState(location.pathname);

  const navi = (item) => {
    setPageAtual(item);
    navigate(item);
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems
          .filter((item) =>
            item.type.includes(user?.typeUser) ? item : item.type === "public"
          )
          .map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={item.path === pageAtual}
                onClick={() => navi(item.path)}
                sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div>{item.icon}</div>
                <ListItemText primary={item.text} sx={{ color: "#144122" }} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>

      {/* <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Stack>
  );
}
